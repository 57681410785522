<template>
  <b-row class="topbar-filter">
    <b-col lg="1" md="3" sm="3">
      <b-button v-if="showFilterButton"
                v-b-toggle.filter-sidebar class="filter-button no-left-space" variant="outline-info"
                @mouseleave="filterHover = false"
                @mouseover="filterHover = true">
        <b-img :src="filterIcon" alt="filter"></b-img>
        &nbsp;&nbsp;
        {{ $t('admin.common.filter') }}
      </b-button>
    </b-col>
    <b-col lg="7" md="5" sm="5">
      <b-form-input
          v-model="currentSearch"
          :placeholder="filterPlaceholder"
          class="search-form"
          type="search"
      />
    </b-col>
    <b-col class="float-right no-left-space" lg="4" md="4" sm="4">
      <ExporterButton
          :export-disabled="exportDisabled"
          :handle-exporter="handleExporter"
          :is-able-to-export="isAbleToExport"
          :show-export="showExport"
      />
      <b-button class="export-button" variant="outline-info"
                @click="table.refresh()"
                @mouseleave="refreshHover = false"
                @mouseover="refreshHover = true">
        <b-img :src="refreshIcon" alt="refresh table"></b-img>
        {{ $t('admin.common.refresh') }}
      </b-button>
      <MoreButton
          :handle-sidebar="handleMoreButton"
          :menu-options="moreButtonMenuOption"
          :show-more="showMoreButton"
      />
      <AddCompanyButton :handle-sidebar="addCompanyProps.onClick"
                        :show-button="addCompanyProps.showButton"
                        :sidebar="addCompanyProps.sidebar"
      />
      <SupportButton :support-filter-props="supportFilterProps"/>
    </b-col>
  </b-row>

</template>
<script>

import ApiUtils from '@/utils/ApiUtils.vue';
import _ from 'lodash';
import ExporterButton from '@/components/Exporter/ExporterButton.vue';
import FilterConstant from "@/components/Filter/FilterConstant.vue";
import MoreButton from "@/components/Exporter/MoreButton.vue";
import AddCompanyButton from "@/components/CompanyData/AddCompanyButton.vue";
import SupportButton from "@/components/Filter/TopbarFilter/SupportButton.vue";

const TIMEOUT = 650;
export default {
    name: 'TopbarFilter',
    components: {SupportButton, AddCompanyButton, MoreButton, ExporterButton},
    mixins: [ApiUtils, FilterConstant],
    props: {
        title: {
            type: String,
            default: () => {
            }
        },
        handleExporter: {
            type: Function,
            default: () => {
            }
        },
        exportDisabled: {
            type: Boolean,
            default: true
        },
        showExport: {
            type: Boolean,
            default: true
        },
        removeSelectedFilter: {
            type: Function,
            default: () => {
            }
        },
        table: {
            type: Object,
            default: () => {
            }
        },
        filter: {
            type: String || Object,
            default: () => {
            }
        },
        filterPlaceholder: {
            type: String,
            default: () => {
            }
        },
        getAmount: {
            type: Function,
            default: () => {
            }
        },
        auth: {
            type: Object,
            default: () => {
            }
        },
        api: {
            type: Object,
            default: () => {
            }
        },
        showFilterButton: {
            type: Boolean,
            default: () => true
        },
        showMoreButton: {
            type: Boolean,
            default: () => false
        },
        handleMoreButton: {
            type: Function,
            default: () => {
            }
        },
        moreButtonMenuOption: {
            type: Array,
            default: () => ([])
        },
        addCompanyProps: {
            type: Object,
            required: false,
            default: () => ({
                showButton: false,
                sidebar: '',
                onClick: () => {}
            })
        },
        supportFilterProps: {
            type: Object,
            required: false,
            default: () => ({
                showButton: false,
                sidebar: '',
                onClick: () => {
                }
            })
        }
    },
    data() {
        return {
            companiesBalances: [],
            exportHover: false,
            refreshHover: false,
            filterHover: false,
            retryHover: false,
            moreInfoHover: false,
            isLoading: false,
            currentSearch: ''
        };
    },
    mounted() {
        this.$root.$on(this.NEW_SEARCH, searchTerm => {
            if (this.currentSearch !== searchTerm) {
                this.currentSearch = searchTerm;
            }
        });
        this.getCompanies();
    },
    computed: {
        refreshIcon() {
            return this.loadImage(`icon-refresh${this.refreshHover ? '-hover' : ''}.svg`);
        },
        retryIcon() {
            return this.loadImage(`icon-refresh${this.retryHover ? '-hover' : ''}.svg`);
        },
        moreInfoIcon() {
            return this.loadImage(`icon_more${this.moreInfoHover ? '-hover' : ''}.svg`);
        },
        filterIcon() {
            return this.loadImage(`filter-button${this.filterHover ? '-hover' : ''}.svg`);
        },
        isAbleToExport() {
            return this.auth.isUserEnabledToPerformOperations() && !this.exportDisabled;
        },
    },
    watch: {
        currentSearch: _.debounce(function () {
            this.$root.$emit('SEARCH-TERM-UPDATED', this.currentSearch);
        }, TIMEOUT)
    },
};
</script>

<style scoped>
.search-form-container {
  height: 42px;
}

.search-form {
  padding: 4px 8px;
  margin: 4px;
  height: 34px;
}

.margin-side {
  margin-right: 2rem;
}

.no-left-space {
  margin-left: 0;
  padding-left: 0;
}
</style>
