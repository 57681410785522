<template>
  <div class="sticky-header">
    <b-row class="header">
      <b-button v-if="isBackButtonActive" variant="link" @click="handleOnClickBack">
        <b-img src="@/assets/images/icon-arrow-back-acquamarine.svg"></b-img>
      </b-button>
      <b-col>
        <h2>{{ title }}</h2>
      </b-col>
      <b-button v-if="isMenuEnabled" variant="link">
        <b-dropdown no-caret size="lg"
                    toggle-class="text-decoration-none"
                    variant="link">
          <template #button-content>
            <b-img src="@/assets/images/icon-more-options.svg"></b-img>
          </template>
          <b-dropdown-item v-for="(item, index) in menuItems.filter(menuItem => menuItem.if === true || menuItem.if === undefined)" :key="index"
                           href="#">
            <div :class="`menu-active ${item.className}`" @click="item.handle">
              {{ item.title }}
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </b-button>
      <b-button v-if="isButtonEnabled" variant="link">
        <b-dropdown no-caret size="lg"
                    toggle-class="text-decoration-none"
                    variant="link">
          <template #button-content>
            <b-img src="@/assets/images/icon-export.svg"></b-img>
          </template>
          <b-dropdown-item href="#">
            <div class="menu-active" @click="handleExporterXlsx">Export as XLSX</div>
          </b-dropdown-item>
          <b-dropdown-item href="#">
            <div class="menu-active" @click="handleExporterPdf">Export as PDF</div>
          </b-dropdown-item>
        </b-dropdown>
      </b-button>
      <b-button variant="link" @click="handleSidebar">
        <b-img src="@/assets/images/icon-close.svg"></b-img>
      </b-button>
    </b-row>
    <hr/>
  </div>
</template>

<script>
export default {
    name: 'SidebarHeader',
    props: {
        title: {
            type: String,
            default: () => 'No Title'
        },
        isBackButtonActive: {
            type: Boolean,
            default: () => false
        },
        isPayment: {
            type: Boolean,
            default: () => false
        },
        isTransaction: {
            type: Boolean,
            default: () => false
        },
        isTransactionUpdate: {
            type: Boolean,
            default: () => false
        },
        handleBackFromTransaction: {
            type: Function,
            default: () => false
        },
        handleBackFromPayment: {
            type: Function,
            default: () => false
        },
        containsButtons: {
            type: Boolean,
            default: false
        },
        isButtonEnabled: {
            type: Boolean,
            default: false
        },
        handleExporterXlsx: {
            type: Function,
            default: () => {
            }
        },
        handleExporterPdf: {
            type: Function,
            default: () => {
            }
        },
        handleSidebar: {
            type: Function,
            default: () => {
            }
        },
        isMenuEnabled: {
            type: Boolean,
            default: () => false
        },
        menuItems: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        handleOnClickBack() {
            switch (true) {
            case (this.isPayment) :
                this.handleBackFromPayment();
                break;
            case (this.isTransaction) :
                this.handleBackFromTransaction();
                break;
            case (this.isTransactionUpdate) :
                this.handleSidebar();
                break;
            default:
                this.handleSidebar();
            }
        }
    }
};
</script>

<style scoped>
.sticky-header {
  position: absolute;
  width: 100%;
  z-index: 10;
  background: #fff;
}
</style>
