<template>
    <b-button v-if="supportFilterProps.showButton"
              class="export-button" variant="outline-info"
              @click="supportFilterProps.onClick()"
              @mouseleave="supportIconRefreshHover = false"
              @mouseover="supportIconRefreshHover = true"
    >
      <b-img :src="supportIcon" alt="refresh table"></b-img>
      {{ $t('admin.transactions.status-problematic')}}
    </b-button>
</template>
<script>
import ApiUtils from "@/utils/ApiUtils.vue";
import FilterConstant from "@/components/Filter/FilterConstant.vue";

export default {
    name: 'SupportButton',
    mixins: [ApiUtils, FilterConstant],
    props: {
        supportFilterProps: {
            type: Object,
            required: true,
            default: () => ({
                showButton: false,
                sidebar: '',
                onClick: () => {
                }
            })
        },
    },
    data() {
        return {
            supportIconRefreshHover: false,
        };
    },
    computed : {
        supportIcon() {
            return this.loadImage(`icon-support${this.supportIconRefreshHover ? '-hover' : ''}.svg`);
        }
    }
};
</script>
