<template>
  <div>
    <TitleBar :handle-sidebar="handleSidebar" :title="title"/>
    <div class="container scrollable-filter">
      <FilterCheckBoxContainer
          :event-on-selection="COMPANY_SELECTED_FILTER"
          :event-on-remove="COMPANY_REMOVED_FILTER"
          :options="optionsCompany"
          name="companies"
          :title="$t('admin.common.companies')"
      />
      <FilterCheckBoxContainer
          :class="[this.optionsCompany.length === 0 ? 'padding-top-for-first-filter-item' : '']"
          :event-on-selection="PAYMENT_TYPE_SELECTED_TRANSACTION_FILTER"
          :event-on-remove="PAYMENT_TYPE_REMOVED_TRANSACTION_FILTER"
          :options="optionsPaymentType"
          name="paymentType"
          :show-status-aggregated="false"
          :title="$t('admin.transactions.transaction-detail.type')"
      />
      <FilterCheckBoxContainer
          :class="[this.optionsCompany.length === 0 ? 'padding-top-for-first-filter-item' : '']"
          :event-on-selection="PAYMENT_STATUS_SELECTED_TRANSACTION_FILTER"
          :event-on-remove="PAYMENT_STATUS_REMOVED_TRANSACTION_FILTER"
          :options="optionsStatus"
          name="paymentStatus"
          :show-status-aggregated="true"
          :title="$t('admin.transactions.transaction-detail.transaction-status')"
      />
      <FilterDatePickerContainer
          id-from="creation-date-picker-from"
          id-to="creation-date-picker-to"
          :event-on-selection-from-date.camel="FROM_DATE_SELECTED_FILTER"
          :event-on-remove-from-date="FROM_DATE_REMOVED_FILTER"
          :event-on-selection-to-date="TO_DATE_SELECTED_FILTER"
          :event-on-remove-to-date="TO_DATE_REMOVED_FILTER"
          :title="$t('admin.transactions.detail.creation-date')"
      />

      <FilterDatePickerContainer
          id-from="exec-date-picker-from"
          id-to="exec-date-picker-to"
          :event-on-selection-from-date.camel="FROM_EXEC_DATE_SELECTED_FILTER"
          :event-on-remove-from-date="FROM_EXEC_DATE_REMOVED_FILTER"
          :event-on-selection-to-date="TO_EXEC_DATE_SELECTED_FILTER"
          :event-on-remove-to-date="TO_EXEC_DATE_REMOVED_FILTER"
          :title="$t('admin.transactions.detail.execution-date')"
      />
      <BlankLine :number-of-rows="3"/>
    </div>
    <b-row>
      <ButtonContainer>
        <template v-slot:mainButton>
          <MainButton :button-text="$t('admin.common.show-result')" :on-click="handleOnClick"/>
        </template>
        <template v-slot:secondaryButton>
          <SecondaryButton :button-text="$t('admin.common.reset-filter')" :on-click="handleOnReset"/>
        </template>
      </ButtonContainer>
    </b-row>
  </div>
</template>

<script>
import TitleBar from '@/components/Layout/Title/TitleBar';
import ButtonContainer from '@/components/Buttons/ButtonContainer';
import MainButton from '@/components/Buttons/MainButton';
import SecondaryButton from '@/components/Buttons/SecondaryButton';
import FilterConstant from '../FilterConstant';
import FilterCheckBoxContainer from '../Common/FilterCheckBoxContainer';
import FilterDatePickerContainer from '../Common/FilterDatePickerContainer';
import FormatUtils from '../../../utils/FormatUtils';
import PaymentStatus from '@/utils/PaymentStatus';
import optionsPaymentTypeWAL from "@/components/Filter/TransactionFilter/optionsPaymentTypeWAL";
import optionsPaymentTypeWALC from "@/components/Filter/TransactionFilter/optionsPaymentTypeWALC";
import BlankLine from "@/components/Blank/BlankLine.vue";

export default {
    name: 'TransactionFilter',
    components: {
        BlankLine,
        FilterDatePickerContainer,
        SecondaryButton,
        MainButton,
        ButtonContainer,
        TitleBar,
        FilterCheckBoxContainer
    },
    mixins: [FilterConstant, FormatUtils],
    props: {
        optionsCompany: {
            type: Array,
            default: () => []
        },
        isWal: {
            type: Boolean,
            default: () => true,
        }
    },
    data() {
        return {
            title: 'Filter',
            selectedCompany: [],
            selectedPaymentType: [],
            selectedStatus: [],
            optionsPaymentType: (this.isWal ? optionsPaymentTypeWAL : optionsPaymentTypeWALC).map(it => {
                return {
                    text: this.$t(it.text),
                    value: it.value
                };
            }),
            optionsStatus: [
                {
                    text: this.$t('admin.transactions.status-paid'),
                    value: PaymentStatus.PAY_COMPLETED.symbol,
                },
                {
                    text: this.$t('admin.transactions.status-failed'),
                    value: PaymentStatus.FAILED.symbol,
                },
                {
                    text: this.$t('admin.transactions.status-refused'),
                    value: PaymentStatus.REFUSED.symbol,
                },
                {
                    text: this.$t('admin.transactions.status-rejected'),
                    value: PaymentStatus.REJECTED.symbol,
                },
                {
                    text: this.$t('admin.transactions.status-to-be-exec'),
                    value: PaymentStatus.TOBE_EXECUTED.symbol,
                },
                {
                    text: this.$t('admin.transactions.status-in-progress'),
                    value: PaymentStatus.INPROGRESS.symbol,
                },
                {
                    text: this.$t('admin.transactions.status-need-approval'),
                    value: PaymentStatus.TOCONFIRM.symbol
                },
                {
                    text: this.$t('admin.transactions.scheduled'),
                    value: PaymentStatus.SCHEDULED.symbol
                },
                {
                    text: this.$t('admin.transactions.status-submitted'),
                    value: this.isWal ?
                        PaymentStatus.PAY_STORED.symbol :
                        PaymentStatus.PAYC_STORED.symbol
                },
                {
                    text: this.$t('admin.transactions.status-on-hold'),
                    value: PaymentStatus.ON_HOLD.symbol
                },
            ],
            toDateSelected: '',
            fromDateSelected: '',
            fromExecDateSelected: '',
            toExecDateSelected: '',
        };
    },
    async mounted() {
    // COMPANY
        this.$root.$on(this.COMPANY_REMOVED_FILTER, (companies) => {
            this.selectedCompany = companies;
        });
        this.$root.$on(this.COMPANY_SELECTED_FILTER, (companies) => {
            this.selectedCompany = companies;
        });
        // PAYMENT TYPE
        this.$root.$on(this.PAYMENT_TYPE_REMOVED_TRANSACTION, (userTypes) => {
            this.selectedPaymentType = userTypes;
        });
        this.$root.$on(this.PAYMENT_TYPE_SELECTED_TRANSACTION_FILTER, (userTypes) => {
            this.selectedPaymentType = userTypes;
        });
        this.$root.$on(this.PAYMENT_TYPE_REMOVED_TRANSACTION_FILTER, (userTypes) => {
            this.selectedPaymentType = userTypes;
        });
        // PAYMENT STATUS
        this.$root.$on(this.PAYMENT_STATUS_REMOVED_TRANSACTION, (status) => {
            this.selectedStatus = status;
        });
        this.$root.$on(this.PAYMENT_STATUS_SELECTED_TRANSACTION_FILTER, (userStatuses) => {
            this.selectedStatus = userStatuses;
        });
        this.$root.$on(this.PAYMENT_STATUS_REMOVED_TRANSACTION_FILTER, (userStatuses) => {
            this.selectedStatus = userStatuses;
        });
        // DATES
        this.$root.$on(this.FROM_DATE_REMOVED, (fromDate) => {
            this.fromDateSelected = fromDate;
        });
        this.$root.$on(this.FROM_DATE_SELECTED_FILTER, (fromDate) => {
            this.fromDateSelected = fromDate;
        });
        this.$root.$on(this.FROM_DATE_REMOVED_FILTER, (fromDate) => {
            this.fromDateSelected = fromDate;
        });
        this.$root.$on(this.TO_DATE_REMOVED, (toDate) => {
            this.toDateSelected = toDate;
        });
        this.$root.$on(this.TO_DATE_REMOVED_FILTER, (toDate) => {
            this.toDateSelected = toDate;
        });
        this.$root.$on(this.TO_DATE_SELECTED_FILTER, (toDate) => {
            this.toDateSelected = toDate;
        });
        this.$root.$on(this.FROM_EXEC_DATE_REMOVED, (fromDate) => {
            this.fromExecDateSelected = fromDate;
        });
        this.$root.$on(this.FROM_EXEC_DATE_SELECTED_FILTER, (fromDate) => {
            this.fromExecDateSelected = fromDate;
        });
        this.$root.$on(this.FROM_EXEC_DATE_REMOVED_FILTER, (fromDate) => {
            this.fromExecDateSelected = fromDate;
        });
        this.$root.$on(this.TO_EXEC_DATE_REMOVED, (toDate) => {
            this.toExecDateSelected = toDate;
        });
        this.$root.$on(this.TO_EXEC_DATE_REMOVED_FILTER, (toDate) => {
            this.toExecDateSelected = toDate;
        });
        this.$root.$on(this.TO_EXEC_DATE_SELECTED_FILTER, (toDate) => {
            this.toExecDateSelected = toDate;
        });
    },
    methods: {
        handleSidebar() {
            this.$root.$emit('bv::toggle::collapse', 'filter-sidebar');
        },
        emitNewCompanies(newCompany) {
            this.$root.$emit(this.COMPANY_SELECTED, newCompany);
        },
        emitNewPaymentType(newUserType) {
            this.$root.$emit(this.PAYMENT_TYPE_SELECTED_TRANSACTION, newUserType);
        },
        emitNewStatus(newStatus) {
            this.$root.$emit(this.PAYMENT_STATUS_SELECTED_TRANSACTION, newStatus);
        },
        emitNewFromDate(newFromDate) {
            this.$root.$emit(this.FROM_DATE_SELECTED, newFromDate);
        },
        emitNewToDate(newToDate) {
            this.$root.$emit(this.TO_DATE_SELECTED, newToDate);
        },
        emitResetCompanies(newCompany) {
            this.$root.$emit(this.COMPANY_SELECTED, newCompany);
            this.$root.$emit(this.COMPANY_REMOVED_FILTER, newCompany);
        },
        emitResetUserType(newUserType) {
            this.$root.$emit(this.PAYMENT_TYPE_SELECTED_TRANSACTION, newUserType);
            this.$root.$emit(this.PAYMENT_TYPE_REMOVED_TRANSACTION_FILTER, newUserType);
        },
        emitResetTransactionStatus(newStatus) {
            this.$root.$emit(this.PAYMENT_STATUS_SELECTED_TRANSACTION, newStatus);
            this.$root.$emit(this.PAYMENT_STATUS_REMOVED_TRANSACTION_FILTER, newStatus);
        },
        emitResetFromDate(newFromDate) {
            this.$root.$emit(this.FROM_DATE_SELECTED, newFromDate);
            this.$root.$emit(this.FROM_DATE_REMOVED_FILTER, newFromDate);
        },
        emitResetToDate(newToDate) {
            this.$root.$emit(this.TO_DATE_SELECTED, newToDate);
            this.$root.$emit(this.TO_DATE_REMOVED_FILTER, newToDate);
        },
        emitNewFromExecDate(newFromExecDate) {
            this.$root.$emit(this.FROM_EXEC_DATE_SELECTED, newFromExecDate);
        },
        emitNewToExecDate(newToExecDate) {
            this.$root.$emit(this.TO_EXEC_DATE_SELECTED, newToExecDate);
        },
        emitResetFromExecDate(newFromExecDate) {
            this.$root.$emit(this.FROM_EXEC_DATE_SELECTED, newFromExecDate);
            this.$root.$emit(this.FROM_EXEC_DATE_REMOVED_FILTER, newFromExecDate);
        },
        emitResetToExecDate(newToExecDate) {
            this.$root.$emit(this.TO_EXEC_DATE_SELECTED, newToExecDate);
            this.$root.$emit(this.TO_EXEC_DATE_REMOVED_FILTER, newToExecDate);
        },
        handleOnClick() {
            this.emitNewCompanies(this.selectedCompany);
            this.emitNewPaymentType(this.selectedPaymentType);
            this.emitNewStatus(this.selectedStatus);
            this.emitNewFromDate(this.fromDateSelected);
            this.emitNewToDate(this.toDateSelected);
            this.emitNewFromExecDate(this.fromExecDateSelected);
            this.emitNewToExecDate(this.toExecDateSelected);
            this.handleSidebar();
        },
        handleOnReset() {
            this.selectedCompany = [];
            this.selectedPaymentType = [];
            this.selectedStatus = [];
            this.fromDateSelected = '';
            this.toDateSelected = '';
            this.fromExecDateSelected = '';
            this.toExecDateSelected = '';
            this.emitResetCompanies(this.selectedCompany);
            this.emitResetUserType(this.selectedPaymentType);
            this.emitResetTransactionStatus(this.selectedStatus);
            this.emitResetFromDate(this.fromDateSelected);
            this.emitResetToDate(this.toDateSelected);
            this.emitResetFromExecDate(this.fromExecDateSelected);
            this.emitResetToExecDate(this.toExecDateSelected);
        },
    }
};
</script>

<style scoped>
.filter-subtitle {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;
  color: #000740;
}
</style>
