<template>
  <b-row>
    <h1 class="page-title" id="page-title">{{ title }}</h1>
  </b-row>
</template>

<script>
export default {
    name: 'TopbarTitle',
    props: {
        title: {
            type: String,
            required: true,
            default: () => 'TopbarTitle',
        },
    },
};
</script>
