<template>
  <b-row>
    <b-col class="selection-tooltip-col no-padding">
      <div v-if="selectionSize > 0" class="selection-tooltip">
        {{ $t('admin.transactions.my-selection', { 'myItem': selectionSize }) }}
        <div v-if="selectionSize === totalItems" class="select-all" @click="deselectAllTheItems">
          {{ $t('admin.transactions.deselect-all', { 'allItem': totalItems }) }}
        </div>
        <div v-if="selectionSize !== totalItems" class="select-all" @click="selectAllTheItems">
          {{ $t('admin.transactions.all-selection', { 'allItem': totalItems }) }}
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
    name: 'SelectionTooltip',
    props: {
        selectionSize :{
            type: Number,
            default: () => 0
        },
        totalItems: {
            type: Number,
            default: () => {
            }
        },
        selectAllTheItems: {
            type: Function,
            default: () => {
            }
        },
        deselectAllTheItems: {
            type: Function,
            default: () => {
            }
        }
    }
};
</script>

<style scoped>
.selection-tooltip-col {
  height: 35px;
  margin-bottom: 1rem !important;
}
</style>
