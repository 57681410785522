<template>
  <b-container v-if="isLoading" class="worker-profile basic-padding scrollable-sidebar" fluid>
    <BlankLine :number-of-rows="5"/>
    <b-row>
      <b-col cols="4">&nbsp;</b-col>
      <b-col cols="4" style="text-align: center;"><b-spinner label="Spinning" type="grow" variant="primary"></b-spinner></b-col>
      <b-col cols="4">&nbsp;</b-col>
    </b-row>
    <b-row v-if="text">
      <b-col cols="4">&nbsp;</b-col>
      <b-col cols="4" style="text-align: center;">{{text}}</b-col>
      <b-col cols="4">&nbsp;</b-col>
    </b-row>
  </b-container>
</template>

<script>
import FormatUtils from "@/utils/FormatUtils.vue";
import BlankLine from "@/components/Blank/BlankLine.vue";

export default {
    name: "Loading",
    components: {BlankLine},
    mixins: [FormatUtils],
    props: {
        isLoading: {
            type: Boolean,
            default: false,
            required: true
        },
        text: {
            type: String,
            default: ""
        }
    }
};
</script>