<template>
  <div>
    <b-row class="header">
      <b-col cols="10">
        <h3 class="filter-title">{{ title }}</h3>
      </b-col>
      <b-col cols="2">
        <b-button v-b-toggle.{{sidebar}} class="filter-close-x" variant="link" @click="handleSidebar">
          <b-img src="@/assets/images/icon-close.svg"></b-img>
        </b-button>
      </b-col>
    </b-row>
    <hr/>
  </div>
</template>

<script>
export default {
    name: 'TitleBar',
    props: {
        title: {
            type: String,
            default: () => 'No Title',
        },
        handleSidebar: {
            type: Function,
            default: () => {
            }
        },
    }
};
</script>

<style scoped>
.filter-title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #000740;
  vertical-align: middle;
  padding: 1rem 0.75rem;
}

.filter-close-x {
  padding: 1rem 0.75rem;
}
</style>
