import PaymentType from "@/utils/PaymentType";

const optionsPaymentTypeWAL =  [
    {
        text:  PaymentType.WAL.name,
        value: PaymentType.WAL.symbol
    },
    {
        text: PaymentType.PAYOUT.name,
        value:  PaymentType.PAYOUT.symbol
    },
    {
        text:  PaymentType.PAYOUTAUTO.name,
        value:  PaymentType.PAYOUTAUTO.symbol
    },
    {
        text:  PaymentType.PAYOUTFEE.name,
        value:  PaymentType.PAYOUTFEE.symbol
    },
];

export default optionsPaymentTypeWAL;