import { render, staticRenderFns } from "./SelectionTooltip.vue?vue&type=template&id=527a3eb6&scoped=true&"
import script from "./SelectionTooltip.vue?vue&type=script&lang=js&"
export * from "./SelectionTooltip.vue?vue&type=script&lang=js&"
import style0 from "./SelectionTooltip.vue?vue&type=style&index=0&id=527a3eb6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "527a3eb6",
  null
  
)

export default component.exports