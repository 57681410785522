import PaymentType from "@/utils/PaymentType";

const optionsPaymentTypeWALC =  [
    {
        text: PaymentType.PIS.name,
        value: PaymentType.PIS.symbol
    },
    {
        text: PaymentType.WALC.name,
        value: PaymentType.WALC.symbol
    },
    {
        text: PaymentType.PAYOUT.name,
        value:  PaymentType.PAYOUT.symbol
    },
    {
        text:  PaymentType.PAYOUTAUTO.name,
        value:  PaymentType.PAYOUTAUTO.symbol
    },
    {
        text: PaymentType.PAYC_ADJNET.name,
        value: PaymentType.PAYC_ADJNET.symbol
    },
    {
        text: PaymentType.PAYC_ADJHOUR.name,
        value: PaymentType.PAYC_ADJHOUR.symbol
    }
];

export default optionsPaymentTypeWALC;