<template>
  <div class="container">
    <title-bar :handle-sidebar="handleSidebar" :title="title"/>
    <div class="worker-details basic-padding">
      <b-row>
        <b-col style="width: auto; flex-grow: 0;" xs>
          <img alt="hide transaction" src="@/assets/images/icon-minus.svg"/>
        </b-col>
        <b-col xs><p ref="btnShowHide" class="filter-subtitle">Company</p></b-col>
      </b-row>
      <b-row>
        <b-form-group>
          <b-form-radio-group
              v-model="selectedCompany"
              :options="optionsCompany"
              name="options"
              stacked
          ></b-form-radio-group>
        </b-form-group>
      </b-row>
    </div>
  </div>
</template>

<script>
import TitleBar from '@/components/Layout/Title/TitleBar';

export default {
    name: 'NotificationFilter',
    components: { TitleBar },
    props: {
        optionsCompany: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            title: 'Filter',
            selectedCompany: -1,
        };
    },
    async mounted() {
        this.$root.$on('NOTIFICATION-TYPE-REMOVED', (userTypes) => {
            this.selectedNotificationType = userTypes;
        });
        this.$root.$on('COMPANY-REMOVED', (companies) => {
            this.selectedCompany = companies;
        });
    },
    methods: {
        handleSidebar() {
            this.$root.$emit('bv::toggle::collapse', 'filter-sidebar');
        },
        emitNewCompanies(newCompany) {
            this.$root.$emit('COMPANY-SELECTED', newCompany);
        },
        emitNewNotificationType(newNotificationTypes) {
            this.$root.$emit('NOTIFICATION-TYPE-SELECTED', newNotificationTypes);
        },
    },
    watch: {
        selectedCompany: {
            handler(newCompany) {
                this.emitNewCompanies(newCompany);
            }
        },
        selectedNotificationType: {
            handler(newNotificationTypes) {
                this.emitNewNotificationType(newNotificationTypes);
            }
        },
    }
};
</script>

<style scoped>
.filter-subtitle {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;
  color: #000740;
}
</style>
